import "../scss/app.scss";

// function domReady(fn) {
//   if (document.readyState !== "loading") {
//     fn();
//     return;
//   }
//   document.addEventListener("DOMContentLoaded", fn);
// }

// function start() {
//   intro();
// }

// domReady(start);
